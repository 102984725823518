<template>
  <div>
    <vx-card>
      <div class="flex justify-between items-center gap-3">
        <div class="w-full">
          <label class="text-sm text-san-juan font-bold">Buscar</label>
          <vs-input
            class="mb-4 w-full md:mb-0 mr-4"
            v-model="searchQuery"
            placeholder="Buscar ventas"
          />
        </div>
        <div class="flex gap-3">
          <div class="w-full">
            <label class="text-sm text-san-juan font-bold"
              >Fecha de inicio</label
            >
            <datepicker
              :language="langEs"
              name="start-date"
              v-model="startDate"
            ></datepicker>
          </div>
          <div class="w-full">
            <label class="text-sm text-san-juan font-bold">Fecha de fin</label>
            <datepicker
              :language="langEs"
              name="end-date"
              v-model="endDate"
            ></datepicker>
          </div>

          <div class="mt-6">
            <vs-button
              type="border"
              class="mb-4 md:mb-0 mr-3 whitespace-no-wrap"
              @click="exportSales"
            >
              Exportar
            </vs-button>
          </div>
        </div>
      </div>

      <div v-if="!loading && !inventorySalesLoading">
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="agGridState.gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="agGridState.defaultColDef"
          :rowData="inventorySales"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :overlayLoadingTemplate="overlayLoadingTemplate"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="agGridState.maxPageNumbers"
          v-model="currentPage"
        />
      </div>
      <div v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
import CellRendererPercentAmount from '@/components/cell-renderer/CellRendererPercentAmount.vue';
import { useAgGrid } from '@/composable/useAgGrid.js';
import { INVENTORY_SALES } from '@/graphql/queries.js';
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/src/locale';
import CellRendererInventorySalesType from './cell-renderer/CellRendererInventorySalesType.vue';

export default defineComponent({
  components: {
    CellRendererAmount,
    CellRendererPercentAmount,
    CellRendererInventorySalesType,
    Datepicker,
  },
  setup(_, { root }) {
    const state = reactive({
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 120,
          filter: true,
        },
        {
          headerName: 'Tipo',
          field: 'type',
          width: 120,
          filter: true,
          cellRendererFramework: 'CellRendererInventorySalesType',
        },
        {
          headerName: 'Cod. Aliado',
          field: 'deliveryman.id',
          width: 120,
          filter: true,
        },
        {
          headerName: 'Nom. Aliado',
          field: 'deliveryman.user.first_name',
          width: 180,
          filter: true,
        },
        {
          headerName: 'Ape. Aliado',
          field: 'deliveryman.user.last_name',
          width: 180,
          filter: true,
        },
        {
          headerName: 'Producto',
          field: 'inventory.name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Precio del producto',
          field: 'inventory.price',
          filter: true,
          width: 180,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Pago en efectivo',
          field: 'cash',
          filter: true,
          width: 160,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Pago en puntos',
          field: 'points',
          filter: true,
          width: 160,
        },
        {
          headerName: 'Financiamiento',
          field: 'financing',
          filter: true,
          width: 160,
        },
        {
          headerName: 'Total de la venta',
          field: 'paid',
          filter: true,
          width: 160,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Pago diario',
          field: 'daily_payment',
          filter: true,
          width: 160,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Deuda actual',
          field: 'current_debt',
          filter: true,
          width: 160,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Cantidad',
          field: 'amount',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Vendido por',
          field: 'creatorUser.first_name',
          width: 150,
          filter: true,
        },
        {
          headerName: 'Compañia',
          field: 'company.name',
          width: 150,
          filter: true,
        },
        {
          headerName: 'Observación',
          field: 'description',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Fecha de venta',
          field: 'created_at',
          width: 300,
          filter: true,
        },
      ],
      loading: false,
      overlayLoadingTemplate:
        '<span>Please wait while your rows are loading</span>',
      startDate: new Date(),
      endDate: new Date(),
    });

    const currentPage = ref(1);
    const first = ref(20);
    const searchQuery = ref('');
    const paginationPageSize = ref('');

    const sDate = computed(() => moment(state.startDate).format('YYYY-MM-DD'));
    const eDate = computed(() => moment(state.endDate).format('YYYY-MM-DD'));

    const { result: inventorySalesResult, loading: inventorySalesLoading } =
      useQuery(
        INVENTORY_SALES,
        {
          page: currentPage,
          first: first,
          search: searchQuery,
          startDate: sDate,
          endDate: eDate,
        },
        {
          fetchPolicy: 'network-only',
        }
      );

    watch(inventorySalesLoading, (value) => {
      state.loading = value;
    });

    const inventorySales = useResult(
      inventorySalesResult,
      [],
      (data) => data.inventorySales.data || []
    );

    const paginatorInfo = useResult(
      inventorySalesResult,
      {
        total: 0,
        perPage: 10,
      },
      (data) => {
        return {
          total: data.inventorySales.paginatorInfo.total || 0,
          perPage: data.inventorySales.paginatorInfo.perPage || 10,
        };
      }
    );

    const totalPages = computed(() =>
      Math.ceil(
        paginatorInfo.value.total || 0 / paginatorInfo.value.perPage || 10
      )
    );

    const { state: agGridState } = useAgGrid({ root });

    const exportSales = () => {
      const fileName = 'historial-ventas.xlsx';
      root.$http
        .post(
          'api/inventory-sales-history/export',
          {
            search: searchQuery,
            startDate: sDate,
            endDate: eDate,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    return {
      ...toRefs(state),
      inventorySalesLoading,
      paginationPageSize,
      agGridState,
      totalPages,
      currentPage,
      inventorySales,
      searchQuery,
      langEs: es,
      exportSales,
    };
  },
});
</script>
